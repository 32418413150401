import { Chat } from "@domain/interfaces/conversation-interface";
import { IFolder, IGetFolderConversationListQuery, IGetFolderListQuery } from "@domain/interfaces/folder-interface";
import { defineStore } from "pinia";
import { useAlertStore } from "./alert-store";
import { useAppStateStore } from "./app-store";

import createFolderLocalUseCase from "@domain/use-cases/folder/local/create-folder";
import createFolderServerUseCase from "@domain/use-cases/folder/server/create-folder";
import getFolderConversationListUseCase from "@domain/use-cases/folder/server/get-folder-conversation";
import getFolderListUseCase from "@domain/use-cases/folder/server/get-list";
import removeFolderUseCase from "@domain/use-cases/folder/server/remove-folder";
import updateFolderServerUseCase from "@domain/use-cases/folder/server/update-folder";

export const useFolderStore = defineStore("folder", {
  state: () => ({
    newFolder: {
      icon: "",
      name: "",
      selectedConversation: [] as Chat[],
    },
    editFolder: {
      id: "",
      name: "",
      icon: "",
      selectedConversation: [] as Chat[],
    },
    data: {
      items: [] as IFolder[],
      fetching: false,
      isMore: false,
      moreFetching: false,
    },
  }),
  actions: {
    async goToEditFolder (item: IFolder) {
      this.editFolder.icon = item.icon;
      this.editFolder.name = item.name;
      this.editFolder.id = item.id;
      const result = await getFolderConversationListUseCase({
        id: item.id,
      });

      this.editFolder.selectedConversation = result.data.contact;
      useAppStateStore().setMiddleColumnSlot("editFolder");
    },

    //
    async getFolderList (query: IGetFolderListQuery) {
      this.data.fetching = true;
      const result = await getFolderListUseCase(query);
      this.data.fetching = false;

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }
      this.data.items = result.data.docs;
      this.data.isMore = result.data.docs.length === result.data.limit;
    },
    async getFolderConversationList (query: IGetFolderConversationListQuery) {
      this.data.fetching = true;
      const result = await getFolderConversationListUseCase(query);
      this.data.fetching = false;

      if (result.code !== 1) {
        useAlertStore().error(result.message);
      }
    },
    async createFolder () {
      const alertStore = useAlertStore();

      if (!this.newFolder.name) {
        alertStore.error("Vui lòng nhập tên folder");

        return;
      }
      const body = {
        icon: this.newFolder.icon,
        name: this.newFolder.name,
        conversations: this.newFolder.selectedConversation.map((item) => item.id),
      };

      this.data.items.unshift(createFolderLocalUseCase(body));
      useAppStateStore().setMiddleColumnSlot("mainFolder");
      this.newFolder.selectedConversation = [];
      this.newFolder.icon = "";
      this.newFolder.name = "";
      createFolderServerUseCase(body);
    },
    async updateFolder () {
      const alertStore = useAlertStore();

      if (!this.editFolder.name) {
        alertStore.error("Vui lòng nhập tên folder");

        return;
      }
      const body = {
        id: this.editFolder.id,
        icon: this.editFolder.icon,
        name: this.editFolder.name,
        conversations: this.editFolder.selectedConversation.map((item) => item.id),
      };

      const index = this.data.items.findIndex((item) => item.id === this.editFolder.id);
      this.data.items[index].name = this.editFolder.name;
      this.data.items[index].icon = this.editFolder.icon;
      this.data.items[index].conversations = this.editFolder.selectedConversation.map((item) => item.id);
      useAppStateStore().setMiddleColumnSlot("mainFolder");
      this.editFolder.selectedConversation = [];
      this.editFolder.icon = "";
      this.editFolder.name = "";
      this.editFolder.id = "";
      updateFolderServerUseCase(body);
    },
    async removeFolder (id: string) {
      const result = await removeFolderUseCase(id);

      if (result.code !== 1) {
        useAlertStore().error(result.message);

        return;
      }
      this.data.items = this.data.items.filter((item) => item.id !== id);
    },
  },
});
