import connectLoginNamespaceUseCase from "@domain/use-cases/account/connect-login-namespace";
import accountConnectToServerUseCase from "@domain/use-cases/account/connect-socket";

import Account from "@domain/entities/account-entity";
import { IAccountLoginBody } from "@domain/interfaces/account-interface";
import loginUseCase from "@domain/use-cases/account/login";
import { getFirebaseToken } from "@infrastructure/firebase/cloud-messaging";
import { defineStore } from "pinia";
import { CornerDotType, CornerSquareType, DotType, DrawType, ErrorCorrectionLevel, Mode, Options, TypeNumber } from "qr-code-styling";
import { useAlertStore } from "./alert-store";

export const useAccountStore = defineStore("account", {
  state: () => ({
    loading: false,
    loginUser: {} as Account,
    token: "",
    qrCodeOptions: {} as Partial<Options>,
  }),

  getters: {
    getLoginUser: (state) => state.loginUser,
    isLogin: (state) => !!state.loginUser.id,
  },
  persist: {
    key: "dataAccess",
    paths: ["loginUser", "token"],
  },
  actions: {
    async loginSuccess (accessToken: string, account: Account) {
      if (account.username) {
        const alert = useAlertStore();
        alert.success(`Welcome back ${account.name}!`);
      }
      const firebaseToken = await getFirebaseToken();

      accountConnectToServerUseCase(this.token, firebaseToken);
      account.firebase = { token: firebaseToken };
      this.loginUser = account;
      this.token = accessToken;
    },
    getQrCodeOptions () {
      const socket = connectLoginNamespaceUseCase();
      socket.on("connect", () => {
        this.qrCodeOptions = {
          width: 300,
          height: 300,
          type: "svg" as DrawType,
          data: JSON.stringify({
            type: "loginChatWeb",
            values: {
              socketId: socket.id,
            },
            data: {
              socketId: socket.id,
            },
          }),
          image: "/favicon.ico",
          margin: 10,
          qrOptions: {
            typeNumber: 0 as TypeNumber,
            mode: "Byte" as Mode,
            errorCorrectionLevel: "Q" as ErrorCorrectionLevel,
          },
          imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 20,
            crossOrigin: "anonymous",
          },
          dotsOptions: {
            color: "var(--color-primary-500)",
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 0,
            //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
            // },
            type: "rounded" as DotType,
          },
          backgroundOptions: {
            color: "var(--color-secondary-100)",
            // gradient: {
            //   type: "linear", // 'radial'
            //   rotation: 0,
            //   colorStops: [
            //     { offset: 0, color: "#ededff" },
            //     { offset: 1, color: "#e6e7ff" },
            //   ],
            // },
          },
          cornersSquareOptions: {
            color: "#35495E",
            type: "extra-rounded" as CornerSquareType,
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
            // },
          },
          cornersDotOptions: {
            color: "#35495E",
            type: "dot" as CornerDotType,
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
            // },
          },
        } as Partial<Options>;
      });
      socket.on("qrCodeExpired", () => {
        console.log("qrCodeExpired");
      });
      socket.on("loginSuccess", (result: { accessToken: string; user: Account }) => {
        socket.disconnect();
        this.loginSuccess(result.accessToken, result.user);
      });
    },

    async logout () {
      this.loginUser = {} as Account;
      this.token = "";
      window.location.reload();
    },
    async login (data: IAccountLoginBody) {
      const result = await loginUseCase(data);

      if (result.data && result.data.accessToken && result.data.user) {
        this.loginSuccess(result.data.accessToken, result.data.user);

        return;
      }

      useAlertStore().error(result.message);
    },
  },
});
