import Folder from "@domain/entities/folder";
import { ICreateFolderBody } from "@domain/interfaces/folder-interface";

export default function createFolderLocalUseCase (body: ICreateFolderBody) {
  return new Folder({
    id: "",
    username: "",
    name: body.name,
    icon: body.icon,
    conversations: body.conversations,
    createdBy: "",
    updatedBy: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  });
}
