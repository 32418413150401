import "@config/index";

import "../public/css/tailwind.css";

import "ant-design-vue/dist/reset.css";

import "../public/css/ant-design.css";

import "../public/css/style.css";

import i18n from "@infrastructure/i18n";
import router from "@infrastructure/router";
import Antd from "ant-design-vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";

import AppAvatar from "@interfaces/components/common/AppAvatar.vue";
import AppButton from "@interfaces/components/common/AppButton.vue";
import AppLoading from "@interfaces/components/common/AppLoading.vue";
import AppTextButton from "@interfaces/components/common/AppTextButton.vue";
import PrimaryButton from "@interfaces/components/common/PrimaryButton.vue";
import DotsThreeVerticalIcon from "@interfaces/components/icon/DotsThreeVerticalIcon.vue";
import ListIcon from "@interfaces/components/icon/ListIcon.vue";
import TextBolderIcon from "@interfaces/components/icon/TextBolderIcon.vue";
import TextItalicIcon from "@interfaces/components/icon/TextItalicIcon.vue";
import TextStrikethroughIcon from "@interfaces/components/icon/TextStrikethroughIcon.vue";
import TextUnderlineIcon from "@interfaces/components/icon/TextUnderlineIcon.vue";
import TrashIcon from "@interfaces/components/icon/TrashIcon.vue";

import { DesktopOutlined, DownOutlined, FileOutlined, KeyOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons-vue";
import AnimatedButton from "@interfaces/components/common/AnimatedButton.vue";
import AppIcon from "@interfaces/components/icon/AppIcon.vue";
import { createPinia } from "pinia";
import { PiniaLogger } from "pinia-logger";
import { createApp } from "vue";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate).use(
  PiniaLogger({
    expanded: true,
    disabled: true, // process.env.mode === "production", // ,
    // use a filter to only log certain actions
    // filter: ({ name }) => name !== "incrementCounter",
    // alternatively, you can specify the actions you want to log
    // if undefined, all actions will be logged
    // actions: ["clear"],
  })
);

createApp(App)
  .use(Antd)
  .use(router)
  .use(i18n)
  .use(pinia)
  .component(PieChartOutlined.name, PieChartOutlined)
  .component(TeamOutlined.name, TeamOutlined)
  .component(DesktopOutlined.name, DesktopOutlined)
  .component(FileOutlined.name, FileOutlined)
  .component(UserOutlined.name, UserOutlined)
  .component(KeyOutlined.name, KeyOutlined)
  .component(DownOutlined.name, DownOutlined)
  .component("AppLoading", AppLoading)
  .component("AppButton", AppButton)
  .component("AppTextButton", AppTextButton)
  .component("AppAvatar", AppAvatar)
  .component("PrimaryButton", PrimaryButton)
  .component("AnimatedButton", AnimatedButton)
  .component("AppIcon", AppIcon)
  // icon
  .component("TextUnderlineIcon", TextUnderlineIcon)
  .component("TextStrikethroughIcon", TextStrikethroughIcon)
  .component("TextItalicIcon", TextItalicIcon)
  .component("TextBolderIcon", TextBolderIcon)
  .component("DotsThreeVerticalIcon", DotsThreeVerticalIcon)
  .component("ListIcon", ListIcon)
  .component("TrashIcon", TrashIcon)

  .mount("#app");
