import { getAccessToken } from "@common/storage/get-access-token";
import { ClientToServerEvents, ISocketResponse, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { Socket, io } from "socket.io-client";
import { handleDebug } from "./helper";

import ErrorCode from "@common/kernel/error-code";
import { getFirebaseToken } from "@infrastructure/firebase/cloud-messaging";
import { useAccountStore } from "@presentation/store/account-store";
import onConnected from "./listen-event/connect";
import onGetConfig from "./listen-event/get-config";
import onNewMessage from "./listen-event/new-message";
import onSendReaction from "./listen-event/send-reaction";

export let socket: Socket<ServerToClientEvents, ClientToServerEvents>;

export const handleSocketConnectWithToken = async (accessToken: string, firebaseToken: string) => {
  socket = io(gConfig.api.host, {
    auth: {
      product: gConfig.api.product,
      token: gConfig.api.token,
      "access-token": accessToken || getAccessToken(),
      firebaseToken: firebaseToken || (await getFirebaseToken()),
    },
    reconnectionDelayMax: 20000,
    query: {},
    withCredentials: true,
    reconnectionDelay: 2000,
    transports: ["polling", "websocket"],
  });

  socket.on("connect_error", (error: any) => {
    handleDebug("connect_error", error);
    try {
      if (!error.data) return;
      const err = <ISocketResponse>JSON.parse(JSON.stringify(error.data));

      if (err?.code === ErrorCode.SESSION_TIMEOUT.code) {
        useAccountStore().logout();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  });

  socket.on("disconnect", (error: any) => {
    handleDebug("disconnect", error);
  });

  socket.on("error", (error: any) => {
    handleDebug("error", error);
  });

  onConnected(socket);
  onNewMessage(socket);
  onSendReaction(socket);
  onGetConfig(socket);
};

export const connectLoginSocket = () => {
  const socketIO: Socket<ServerToClientEvents, ClientToServerEvents> = io(gConfig.api.host + "/login", {
    auth: {
      product: gConfig.api.product,
      token: gConfig.api.token,
    },
    reconnectionDelayMax: 20000,
    query: {},
    withCredentials: true,
    reconnectionDelay: 2000,
    transports: ["polling", "websocket"],
  });

  return socketIO;
};
