import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => ({
    color: {
      colorPrimary: "#007E00",
      colorBorder: "#D4D8E3",
    },
    theme: { token: { colorPrimary: "#007E00", colorBorder: "#D4D8E3" } },
    today: new Date(),
  }),
  getters: {},
  persist: false,
  actions: {
    setColorPrimary (color: string) {
      this.theme.token.colorPrimary = color;
    },
    setToday (date: Date) {
      this.today = date;
    },
  },
});
