import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export function onReceivedMessage () {
  const app = initializeApp(gConfig.firebaseConfig);
  const messaging = getMessaging(app);
  onMessage(messaging, (payload: any) => {
    console.log("Message received . ", payload);
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(payload.notification.title, {
        body: payload.notification.body,
        icon: "../images/touch/chrome-touch-icon-192x192.png",
      });
    });
  });
}

export async function getFirebaseToken () {
  try {
    if (window.Notification && Notification.permission === "granted") {
      const app = initializeApp(gConfig.firebaseConfig);
      const messaging = getMessaging(app);

      return await getToken(messaging, { vapidKey: "BKQvh2MAnUEDslg1i92xFyFHLRa26pK0n8qaJ4L8mO7eVl0ZYV4Gev2qS1LBCsYhcCvuJrUqimBj2qpP17WLuks" });
    }

    return "";
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);

    return "";
  }
}
