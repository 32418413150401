import searchConversationUseCase from "@domain/use-cases/conversation/search-conversation";
import searchMessageUseCase from "@domain/use-cases/message/search-message";

import { MAX_LIMIT_SEARCH } from "@common/constant";
import { Chat } from "@domain/interfaces/conversation-interface";
import { SearchMessageItem } from "@domain/interfaces/message-interface";
import { defineStore } from "pinia";

export const useSearchStore = defineStore("search", {
  state: () => ({
    recently: [] as Chat[],
    conversation: {
      items: [] as Chat[],
      fetching: false,
    },
    messages: {
      page: 1,
      items: [] as SearchMessageItem[],
      fetching: false,
      moreFetching: false,
      isMore: false,
    },
    searchText: "",
    showSearch: false,
  }),
  persist: false,
  getters: {
    searchMore: (state) => state.messages.isMore && !state.messages.moreFetching && !state.messages.fetching,
  },
  actions: {
    async onSearch () {
      if (this.searchText.trim().length === 0) return;

      this.searchConversation();
      this.searchMessage();
    },
    async searchConversation (searchText?: string) {
      if (this.messages.fetching) return;

      this.conversation.items = [] as Chat[];
      this.conversation.fetching = true;

      const result = await searchConversationUseCase({ search: searchText || this.searchText });

      if (result.code == 1) {
        this.conversation.items = result.data.items;
      }
      this.conversation.fetching = false;
    },
    async searchMessage () {
      if (this.messages.fetching) return;

      this.messages.page = 1;
      this.messages.items = [] as SearchMessageItem[];
      this.messages.fetching = true;

      const result = await searchMessageUseCase({ search: this.searchText, page: 1 });

      if (result.code === 1) {
        this.messages.items = result.data.items;
        this.messages.isMore = result.data.items.length === MAX_LIMIT_SEARCH;
      }
      this.messages.fetching = false;
    },
    async searchMoreMessage () {
      if (!this.messages.isMore || this.messages.moreFetching || this.messages.fetching) return;

      this.messages.moreFetching = true;
      const result = await searchMessageUseCase({ search: this.searchText, page: this.messages.page + 1 });

      if (result.code === 1) {
        this.messages.page = this.messages.page + 1;
        this.messages.items = this.messages.items.concat(result.data.items);
        this.messages.isMore = result.data.items.length === MAX_LIMIT_SEARCH;
      }

      this.messages.moreFetching = false;
    },
    hideSearch () {
      this.showSearch = false;
      this.searchText = "";
    },
  },
});
